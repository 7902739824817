import util from "./util";
import {
  pmailrsonnelRecordsManagementDetail,
  getSlt,
  save,
  getmarket,
  callTYC,
  tempSave,
  dimission,
  showFullIdNumber,
  personnelRecordsManagementDetail,
  ocrRecognizeIdCard, deleteShopByAccount
} from "./api";
import { publicPath } from "@/common/constant.js";
import { downPoster } from "@/views/monopolyShopActivity/campaignList/api";
import { getPrincipal } from "@/views/distributionStores/addDistributionStore/api";
export default {
  name: "index",
  data() {
    return {
      // 面包屑数据
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "/basicInfo?type=2",
          name: "/basicInfo?type=2",
          title: "业务人员档案",
        },
        {
          path: "/editPerson",
          name: "editPerson",
          title: "人员档案" + (this.$route.query.id ? "编辑" : "新增"),
        },
      ],

      spinning: false, //页面整体loading
      editCount:0,//表单编辑次数 >1则有编辑
      menuList: [
        {
          title: "业务人员角色信息",
          active: true,
        },
        {
          title: "基本信息",
          active: false,
        },
        {
          title: "教育经历",
          active: false,
        },
        {
          title: "工作经历",
          active: false,
        },
      ],
      isFixed: false, //左侧是否跟随
      // ***********select下拉数据****************//
      selectData: {
        marketCenter: [], //营销中心
        politiceFace: [], //政治面貌
        nation: [], //民族
        workExperience: [], //家电工作年限
        education1: [], //学历全日制
        education2: [], //学历非全日制
        jobs: [], // 岗位
      },
      // ***********select下拉数据 End****************//
      infoForm: {
        jobsId: "", //岗位id写死
        jobsName: "", //岗位
        custFullName: "", //代理商全称
        cisCode: "", //CIS编码
        custAccountName: "", //用户账号
        matklIds: [], //物料组 逗号拼接
        hisenseStaff:'1',
        sex: "1", //性别 默认男
        marriage: "1", //婚姻状况 默认已婚
        fullTimeEducation: "1", //最高学历 默认全日制
        idCard:'',
        age:'',
        birthday:'',
        name:'',
        studyDate:[],
        principalName:undefined,
        custAccountPersonWorkDtoList: [
          //工作经历
          // {
          //   "personId": 10001111,
          //   "startDate": "2021-02-60",
          //   "endDate": "2022-02-50",
          //   "company": "测试公司1",
          //   "position": "测试职位1"
          // }
        ],
        // name:'mingzi',
        // gw:'岗位',
        // resource:'1',
        // date:'',
        // region:'',
      },
      principal: [], //办事处经理
      jobsList:[
        {
          code: '14181287847',
          name: '运营经理-操盘手'
        },
        {
          code: '14181287852',
          name: '运营经理-业务员'
        },
        {
          code: '14181287855',
          name: '运营经理-市场推广'
        },
        {
          code: '17594638392',
          name: '下沉渠道专职运营经理'
        },

      ],
      // baseMatklList:[],
      isAddDialogShow: false, //添加工作经历弹窗显示隐藏
      workForm: {
        //工作经历表单
        company: undefined,
        position: "",
        date: "",
      },
      workArr:[],
      searchLoading: false, //搜索loadding
      workRules: {
        //工作经历表单验证规则
        company: [
          { required: false, message: "请输入公司名称", trigger: "blur" },
        ],
        position: [
          { required: false, message: "请输入职位名称", trigger: "blur" },
        ],
        date: [
          { required: false, message: "请选择在职时间", trigger: "change" },
        ],
      },
      addDialogActive: false, //工作经历闪烁提示动画
      detail: {
        custAccountPersonWorkDtoList: [
          // {
          //   "personId": 10001111,
          //   "startDate": "2021-02-60",
          //   "endDate": "2022-02-50",
          //   "company": "测试公司1",
          //   "position": "测试职位1"
          // }
        ],
      },
      oldMatklIds: [],
      confirmStatus: false,
      //====================头像上传相关=====================//
      fileList: [],
      previewVisible: false,
      previewImage: "",
      //====================头像上传相关End=====================//
      //表单验证
      rules: {
        hisenseStaff: [
          {
            required: true,
            message: "请选择是否是原海信员工",
            trigger: ["change", "blur"],
          },
        ],
        //营销中心
        marketCenterIds: [
          {
            required: true,
            message: "请选择营销中心",
            trigger: ["change", "blur"],
          },
        ],
        //物料组/负责产品线
        // jobsId: [
        //   { required: true, message: "请选择岗位", trigger: "blur" },
        // ],
        //物料组/负责产品线
        matklIds: [
          { required: true, message: "请选择物料组", trigger: "blur" },
        ],

        //姓名
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { max: 10, message: "姓名不可超过10个字", trigger: "blur" },
        ],

        //电话
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: this.$util.MobileNumberValidator,
            trigger: 'blur',
          },
          {
            validator: this.$util.NumberValidator,
            trigger: ["change", "blur"],
          },
          // { type: 'number', message: '请输入数字', trigger: 'change' },
        ],
        //身份证
        idCard: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            validator: this.$util.IDValidator,
            trigger: ["change", "blur"],
          }
        ],
        //邮箱
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { validator: this.$util.EmailValidator, trigger: "blur" },
          // { type: 'number', message: '请输入数字', trigger: 'change' },
        ],
        //性别
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: ["change", "blur"],
          },
        ],
        //婚姻状况
        marriage: [
          {
            required: false,
            message: "请选择婚姻状况",
            trigger: ["change", "blur"],
          },
        ],
        //出生年月
        birthday: [
          {
            required: true,
            message: "请选择出生年月",
            trigger: ["change", "blur"],
          },
        ],
        //年龄
        age: [
          {
            required: true,
            message: "请选择出生年月后获得年龄",
            trigger: "blur",
          },
        ],
        //政治面貌
        politiceFaceId: [
          {
            required: false,
            message: "请选择政治面貌",
            trigger: ["change", "blur"],
          },
        ],
        //民族
        nationId: [
          {
            required: false,
            message: "请选择民族",
            trigger: ["change", "blur"],
          },
        ],
        //工作年限
        workTimeId: [
          {
            required: true,
            message: "请选择家电行业工作年限",
            trigger: ["change", "blur"],
          },
        ],

        //最高学历
        fullTimeEducation: [
          {
            required: true,
            message: "请选择最高学历是否是全日制",
            trigger: ["change", "blur"],
          },
        ],
        //全日制最高学历/学位
        educationId: [
          {
            required: true,
            message: "请选择最高学历/学位",
            trigger: ["change"],
          },
        ],
        //非全日制最高学历/学位
        // educationId2: [
        //   {
        //     required: true,
        //     message: "请选择非全日制最高学历/学位",
        //     trigger: "blur",
        //   },
        // ],
        //毕业时间
        studyDate: [
          {
            required: true,
            message: "请选择毕业时间",
            trigger: ["change", "blur"],
          },
        ],
        //毕业院校
        school: [
          { required: false, message: "请输入毕业院校", trigger: "blur" },
        ],
        //专业
        major: [{ required: false, message: "请输入所学专业", trigger: "blur" }],
      },
      // 销售组织数据
      baseMatklList: [
        // {
        //   id: 0,
        //   matklName: "全部",
        //   isCheck: false,
        // },
      ], // 物料组
      beforMatklist:[],
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      scrollWatch:true,//滚动flag
      info: '',
      //====================身份证正面上传相关=====================//
      fileListIZ: [], // 身份证正面
      // previewVisible: false,
      // previewImage: "",
      //====================身份证面上传相关End=====================//
      fileListIF: [],//身份证反面
      fileListeducation:[],//学历照片
      fileListinterviewResults:[], //面试结果
      isShowUpload:false,
      isShowUploadEdution:false,
      disabled:false,
      confirmToast:false,
      showTips:false,
      disabledFront:false,
      disabledBack:false,
      accountType:'',
      noPrincipalName:false,
    };
  },
  watch: {
    infoForm: {
      handler (val) {
        if (val) {
          this.editCount++
        }
        if(val.workTimeId == 90301) {
            this.workRules = {
                company: [
                  { required: false, message: "请输入公司名称", trigger: "blur" },
                ],
                position: [
                  { required: false, message: "请输入职位名称", trigger: "blur" },
                ],
                date: [
                  { required: false, message: "请选择在职时间", trigger: "change" },
                ],
              }
        } else {
            this.workRules = {
                company: [
                  { required: true, message: "请输入公司名称", trigger: "blur" },
                ],
                position: [
                  { required: true, message: "请输入职位名称", trigger: "blur" },
                ],
                date: [
                  { required: true, message: "请选择在职时间", trigger: "change" },
                ],
              }
        }
      },
      deep: true
    }
  },
  // beforeRouteLeave(){
  //   // alert(1)
  //   //取消滚动监听
  //   window.EventListener("scroll", this.handleScroll,false);
  // },
  destroyed() {
    this.scrollWatch = false;
  },
  mounted() {
    this.accountType=this.$store.state.user.userInfo.account.type
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    // window.addEventListener("scroll", this.handleScroll);
    $(window).on("scroll", this.handleScroll);
    // this.personnelRecordsManagementDetail();


    if (this.$route.query.id ) {

      this.infoForm = { ...this.infoForm, id: this.$route.query.id };
      this.personnelRecordsManagementDetail();
    } else {
      this.disabled = false
      this.getMrketList();
    }
    if(this.$store.state.user.userInfo.isAgentOperator == 1){
      this.menuList.push({
        title: "面试结果",
        active: false,
      })
    }
    if(this.$store.state.user.userInfo.isAgentOperator == 0) {
      this.isShowUpload = false
    } else {
      this.isShowUpload = true
    }

  },
  methods: {
    principalInput(e) {
      let data = {
        searchstr: e,
      };
      getPrincipal(data).then((res) =>{
        if (res.data.code == 0) {
          this.principal = res.data.list;
        }
      });
    },
    // 办事处经理改变
    principalChange(e) {
      this.infoForm.ldapUserId = this.principal[e].code;
      this.infoForm.principalName = this.principal[e].name;
      this.infoForm = { ...this.infoForm };
      if(! this.infoForm.ldapUserId){
        this.noPrincipalName = true
      }else {
        this.noPrincipalName = false
      }
    },
    // 办事处经理失去焦点
    handleBlur(){
      if(!this.infoForm.ldapUserId){
        this.noPrincipalName = true
      }else {
        this.noPrincipalName = false
      }
    },
    // 岗位失去焦点
    jobsBlur() {
      if(!this.infoForm.jobsId) {
        this.showTips = true
      } else {
        this.showTips = false
      }
    },
    // 修改岗位
    changejobs() {
      if(!this.infoForm.jobsId) {
        this.showTips = true
      } else {
        this.showTips = false
      }
       this.selectData.jobs.forEach(it => {
        if(it.code == this.infoForm.jobsId ) {
          this.infoForm.jobsName = it.name
        }
      })
    },
    delectXL(){
      this.fileListeducation = []
      this.infoForm.educationPicId = '';
    },
    changeEducation(){
      if(this.infoForm.educationId !== '90405'
        && this.infoForm.educationId !== '90406'
        && this.infoForm.educationId !== '90407'
        && this.infoForm.educationId !== '90408'
        && this.infoForm.educationId !== '90409' && this.$store.state.user.userInfo.isAgentOperator == 1 || 
        this.$store.state.user.userInfo.isAgentOperator == 0
        ){
        // this.fileListeducation = []
        // this.infoForm.educationPicId = ''
        this.isShowUploadEdution = false
      }else {
        this.isShowUploadEdution = true
      }
    },
    downLoad(item){
      // 如果是pdf直接打开
      if(item.url.indexOf('pdf') !==-1){
        window.open(item.url, "_blank");
        return
      }
      let data = {
        urlString:item.url
      }
      downPoster(data).then(res=>{
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", item.name + ".jpg");
        document.body.appendChild(link);
        link.click();
      })

    },
    // 查看完整身份证号
    ViewFullId(){
      let data = {
        personId: this.$route.query.id.toString(),
        secrecy:'N'
      }
      showFullIdNumber(data).then(res=>{
        this.infoForm.idCard = res.data
      })
    },
    //判断只读状态
    isDisabled(){
      if(this.$route.query.id){
        //点击编辑
        // if(this.$route.query.isAllEdit == "true"){
        if(this.info.isIdCardEditable != 0){
          //是否可以全字段编辑
          return false
        }else{
          return true
        }
      }else{
        //新增
        return false
      }
    },
    //文本框值变化时回调
    handleSearch(value) {
      this.workArr = [];
      this.searchLoading = true;

      callTYC({word:value}).then((res)=>{
        if(res.data.error_code == 0){
          this.workArr = res.data.result.items;

        }
        this.searchLoading = false;
      }).catch(error => {
        console.log(error);
        this.searchLoading = false;
      });

    },
    //被选中时调用，参数为选中项的 value (或 key) 值
    handleSelect(value) {
      this.workForm.company = value;
    },
    //头像上传
    beforeUpload(file) {
      if (file.size / 1024  / 1024 <= 10) {
        this.fileList = [...this.fileList, file];
      } else {
        this.$message.info("图像超出10M,请重新上传");
      }
      // return false;
    },
    beforeUpload1(file) {
      if (file.size/ 1024 / 1024 <= 10) {
        this.fileListIZ = [...this.fileListIZ, file];
      }else {
        this.$message.info("图像超出10M,请重新上传");
      }
      // return false;
    },
    beforeUpload2(file) {
      if (file.size/ 1024 / 1024 <= 10) {
        this.fileListIF = [...this.fileListIF, file];
      } else {
        this.$message.info("图像超出10M,请重新上传");
      }
      // return false;
    },
    beforeUpload3(file) {
      if (file.size/ 1024 / 1024 <= 10) {
        this.fileListeducation = [...this.fileListeducation, file];
      } else {
        this.$message.info("图像超出10M,请重新上传");
      }
    },
    beforeUpload4(file) {
      if (file.size/ 1024 / 1024 <= 10) {
        this.fileListinterviewResults = [...this.fileListinterviewResults, file];
      } else {
        this.$message.info("图像超出10M,请重新上传");
      }

      // return false;
    },
    //上传格式错误
    errorAcceptfun(e, e1) {
      console.log(e, e1);
    },
    //取消
    handleCancel() {
      this.previewVisible = false;
    },
    //查看
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    //上传状态改变
    handleChange1(info) {
      if (info.file.size / 1024/ 1024 / 1024>10) {
        return;
      }
      this.fileList = info.fileList;
      if (info.file.status === "done") {
        if (info.file.response.message == "上传成功") {
          this.fileList = info.fileList;
          //赋值头像Id
          this.infoForm.headPicId = info.file.response.businessId;
        } else {
          this.$message.warning("上传失败，请联系管理员");
        }
      }
      if(info.file.status === "removed"){
        //删除置空
        this.infoForm.headPicId = '';
      }
    },
    ocrRecognizeIdCardBefor(data){
      return ocrRecognizeIdCard(data)
    },
    async  handleChangeother(info,type) {
      if (info.file.size / 1024 / 1024 > 10) {
        return;
      }
      if( type == 'idCardZ' ) {
       this.fileListIZ = info.fileList;
       if (info.file.status === "done") {
         if (info.file.response.message == "上传成功") {
           this.fileListIZ = info.fileList;
           //赋值Id
           this.infoForm.idCardFrontId = info.file.response.businessId;

           if((!this.disabledFront && this.info.recordType
                   && this.info.recordType.indexOf('生效') == -1
                   &&  this.info.recordType.indexOf('已绑定') == -1
               )
             || !this.$route.query.id
             ) {
             const res = await this.ocrRecognizeIdCardBefor({attachId: this.infoForm.idCardFrontId})
             if(res.data.code == 400) {
               this.infoForm.idCard = ''
               return
             }
             this.infoForm.name = res.data.name
             this.infoForm.idCard = res.data.idCard?String(res.data.idCard):''
             this.infoForm.sex = res.data.sex=='男'?'1':'0'
             this.$refs.ruleForm.validateField('idCard', (valid) => {
               if (!valid) {
                 //验证通过
                 this.infoForm.age = this.getAge(this.infoForm.idCard);
                 this.infoForm.birthday = this.getBirthdayFromIdCard(this.infoForm.idCard);
                 this.$forceUpdate()
                 return false
               }else{
                 this.infoForm.age = '';
                 this.infoForm.birthday = '';
               }
               this.$forceUpdate()
             });

           }

         } else {
           this.$message.warning("上传失败，请联系管理员");
         }
       }
       if(info.file.status === "removed"){
         //删除置空
         this.infoForm.idCardFrontId = '';
       }
     }
      if( type == 'idCardF' ) {
        this.fileListIF = info.fileList;
        if (info.file.status === "done") {
          if (info.file.response.message == "上传成功") {
            this.fileListIF = info.fileList;
            //赋值头像Id
            this.infoForm.idCardBehindId = info.file.response.businessId;
          } else {
            this.$message.warning("上传失败，请联系管理员");
          }
        }
        if(info.file.status === "removed"){
          //删除置空
          this.infoForm.idCardBehindId = '';
        }
      }
      if( type == 'educationPicture' ) {
        this.fileListeducation = info.fileList;
        if (info.file.status === "done") {
          if (info.file.response.message == "上传成功") {
            this.fileListeducation = info.fileList;
            this.fileListeducation[0].url = info.file.response.url
            this.$forceUpdate()
            //赋值头像Id
            this.infoForm.educationPicId = info.file.response.businessId;
          } else {
            this.$message.warning("上传失败，请联系管理员");
          }
        }
        if(info.file.status === "removed"){
          //删除置空
          this.infoForm.educationPicld = '';
        }
      }
      if( type == 'interviewResults' ) {
        this.fileListinterviewResults = info.fileList;
        if (info.file.status === "done") {
          if (info.file.response.message == "上传成功") {
            this.fileListinterviewResults = info.fileList;

            //赋值头像Id
            this.infoForm.interviewId = info.file.response.businessId;
          } else {
            this.$message.warning("上传失败，请联系管理员");
          }
        }
        if(info.file.status === "removed"){
          //删除置空
          this.infoForm.interviewId = '';
        }

      }

    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    //左侧导航点击
    navClickFun(index, isScroll) {
      for (const idx in this.menuList) {
        if (idx == index) {
          if (!isScroll) {
            $("body,html").animate(
              {
                // scrollTop: $("#type" + index).offset().top - 80,
                scrollTop: $("#type" + index).offset().top,
              },
              // 360
              0
            );
          }
          //活动状态
          let timer = setTimeout(() => {
            for (const idx in this.menuList) {
              this.menuList[idx].active = false;
            }
            this.menuList[index].active = true;
            clearTimeout(timer);
          }, 1);
          return;
        }
      }
    },

    //左侧导航跟随滚动
    navScrollFun(index, isScroll) {
      for (const idx in this.menuList) {
        this.menuList[idx].active = false;
      }
      for (const idx in this.menuList) {
        if (idx == index) {
          this.menuList[index].active = true;
          return;
        }
      }
    },
    //判断详情通栏是否吸附
    handleScroll() {
      if(!this.scrollWatch){
        return
      }
      if ($("#boxFixedRefer").offset() && $("#boxFixedRefer").offset().top) {
        let scrollTop =
          $("#boxFixedRefer").offset().top - $(window).scrollTop();
        if (scrollTop <= 0) {
          this.isFixed = true;
        } else {
          this.isFixed = false;
        }
      }
      //滚动-》左侧交互
      if ($("#type3").offset().top - $(window).scrollTop() < 40) {
        this.navScrollFun(3, true);
      } else if ($("#type2").offset().top - $(window).scrollTop() < 40) {
        this.navScrollFun(2, true);
      } else if ($("#type1").offset().top - $(window).scrollTop() < 40) {
        this.navScrollFun(1, true);
      } else if ($("#type0").offset().top - $(window).scrollTop() < 40) {
        this.navScrollFun(0, true);
      }
    },

    //左侧点击交互 暂时不用
    navJumpFun(index) {
      for (const idx in this.menuList) {
        this.menuList[idx].active = false;
      }
      this.menuList[index].active = true;
    },

    //详情接口
    async personnelRecordsManagementDetail() {
      const data = {
        personId: this.$route.query.id,
      };
      const res = await personnelRecordsManagementDetail(data);
      if (res.data.code == 0) {
        this.$nextTick(() => {
          //获取物料组 并选中
          this.getMrketList();
          // this.infoForm = res.data.data;
          let data_ = res.data.data;
          this.info = data_;
          // data
          //数字类型转字符串 -》 方便下拉取值
          for (const key in data_) {
            if (parseFloat(data_[key]).toString() != "NaN") {
              data_[key] = data_[key].toString();
            }
          }
          getPrincipal({ searchstr: this.info.ldapUserName && this.info.ldapUserName.split('-')[0]}).then((resPrincipal) => {
            if (resPrincipal.data.code == 0) {
              this.principal = resPrincipal.data.list;
              this.infoForm.ldapUserId = this.info.ldapUserId;
              this.infoForm.principalName = this.info.ldapUserName;
            }
          });
          this.infoForm = { ...this.infoForm, ...data_ };

          // this.infoForm.jobsId = "";

          //教育经历开始结束时间
          this.infoForm.studyDate = [
            this.infoForm.beginStudyDate,
            this.infoForm.endStudyDate,
          ];

          // this.infoForm.matklIds = 20160119014,20160119014
          //营销中心
          this.infoForm.marketCenterIds = this.infoForm.marketCenterIds ? this.infoForm.marketCenterIds.split(',') : [];
          this.infoForm.matklIds = this.infoForm.matklIds ? this.infoForm.matklIds.split(',') : [];
          this.oldMatklIds = this.infoForm.matklIds
          //头像默认
          if(res.data.data.headPicPath) {
            this.fileList = [
              {
                uid: "-1",
                name: "头像",
                status: "done",
                url: res.data.data.headPicPath,
              },
            ];
          }

          if(res.data.data.idCardFrontPath!==null){
            // 身份证正面
            this.fileListIZ = [
              {
                uid: "-1",
                name: "身份证正面",
                status: "done",
                url: res.data.data.idCardFrontPath,
              },
            ];
          }

          if(res.data.data.idCardBehindPath!==null) {
            // 身份证反面
            this.fileListIF = [
              {
                uid: "-1",
                name: "身份证反面",
                status: "done",
                url: res.data.data.idCardBehindPath,
              },
            ];
          }

          if ( res.data.data.recordType && (res.data.data.recordType.indexOf('生效') >= 0  || res.data.data.recordType.indexOf('已绑定') >= 0)
          ) {
            if(res.data.data.idCardFrontPath){
              this.disabledFront = true
            }else{
              this.disabledFront = false
            }
            if(res.data.data.idCardBehindPath){
              this.disabledBack = true
            }else{
              this.disabledBack = false
            }
            this.disabled = true
          } else {
            this.disabled = false
          }
          if(this.$store.state.user.userInfo.isAgentOperator == 0) {
            this.isShowUpload = false
          } else {
            if(this.infoForm.educationId == 90405
                || this.infoForm.educationId == 90406
                || this.infoForm.educationId == 90407
                || this.infoForm.educationId == 90408
                || this.infoForm.educationId == 90409 ){
              this.isShowUploadEdution = true
            }
          }
          if((this.infoForm.educationId !== '90405'
            && this.infoForm.educationId !== '90406'
            && this.infoForm.educationId !== '90407'
            && this.infoForm.educationId !== '90408'
            && this.infoForm.educationId !== '90409' && this.isShowUploadEdution) || !this.isShowUploadEdution){
            this.fileListeducation = []
            this.infoForm.educationPicId = ''
          } else {
            // 学历证明
            if(res.data.data.educationPicPath) {
              this.fileListeducation = [
                {
                  uid: "-1",
                  name: "学历证明",
                  status: "done",
                  url: res.data.data.educationPicPath,
                },
              ];
            }
          }



          // 面试结果
          if(res.data.data.interviewPath) {
            this.fileListinterviewResults = [
              {
                uid: "-1",
                name: "面试结果",
                status: "done",
                url: res.data.data.interviewPath,
              },
            ];
          }


          // this.infoForm.matklIds = "20160119018,20160119011";
        });
      }
      
    },

    same(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false
      }
      return arr1.every(item => arr2.indexOf(item) > -1)
    },

    // 点击取消 就会把只附上之前选中数据
    cancle(){
      this.infoForm.matklIds = this.oldMatklIds
      this.saveSubmit()
    },
    handleSubmit(){
      // 旧数据要更新
      this.oldMatklIds = this.infoForm.matklIds
      this.saveSubmit()
    },
    clickFun() {

      this.$refs.ruleForm.validate((valid) => {});
    },
    //判断两个时间是否有交集例如：start1=2019-7-20,end1=2019-7-23

    isDateIntersection(start1, end1, start2, end2) {
      var startdate1 = new Date(start1.replace("-", "/").replace("-", "/"));
      var enddate1 = new Date(end1.replace("-", "/").replace("-", "/"));

      var startdate2 = new Date(start2.replace("-", "/").replace("-", "/"));
      var enddate2 = new Date(end2.replace("-", "/").replace("-", "/"));

      if (startdate1 >= startdate2 && startdate1 <= enddate2) {
        return true;
      }

      if (enddate1 >= startdate2 && enddate1 <= enddate2) {
        return true;
      }

      if (startdate1 <= startdate2 && enddate1 >= enddate2) {
        return true;
      }
      return false;
    },
    //工作经历 确定
    saveWorkItemFun() {
      this.$refs.workForm.validate((valid) => {
        if (valid) {
           //验证工作经历时间不重叠
          if(this.infoForm.custAccountPersonWorkDtoList && this.infoForm.custAccountPersonWorkDtoList.length > 0){
            for (const item of this.infoForm.custAccountPersonWorkDtoList) {
              let startDate = this.$util.dateFmt(
                new Date(this.workForm.date[0]),
                "yyyy-MM-dd"
              );
              let endDate = this.$util.dateFmt(
                new Date(this.workForm.date[1]),
                "yyyy-MM-dd"
              );
              if(this.isDateIntersection(startDate,endDate,item.startDate,item.endDate)){
                this.$message.info("工作经历日期重叠，请重新选择", 2);
                return
              }
            }
          }
          //定义工作经历条目 并插入工作经历数组中
          let workItem = {};
          workItem["startDate"] = this.$util.dateFmt(
            new Date(this.workForm.date[0]),
            "yyyy-MM-dd"
          );
          workItem["endDate"] = this.$util.dateFmt(
            new Date(this.workForm.date[1]),
            "yyyy-MM-dd"
          );
          workItem["company"] = this.workForm.company;
          workItem["position"] = this.workForm.position;
          if (
            this.infoForm.custAccountPersonWorkDtoList == null ||
            this.infoForm.custAccountPersonWorkDtoList == ""
          ) {
            this.infoForm.custAccountPersonWorkDtoList = [];
            this.infoForm.custAccountPersonWorkDtoList.push(workItem);
          } else {
            this.infoForm.custAccountPersonWorkDtoList.push(workItem);
          }
          this.isAddDialogShow = false;
          //重置
          this.$refs.workForm.resetFields();
        } else {
          return false;
        }
      });

    },

    // 获取门店物料 和下拉
    getMrketList() {
      this.spinning = true;
      let data = {};
      getmarket(data)
        .then((res) => {
          // res.data.data.baseMatklList.forEach((item) => {
          //   item.isCheck = false;
          // });
          
          this.spinning = false;
          // this.baseMatklList.push(...res.data.data.baseMatklList)

          // let baseMatklList = [
          //   {
          //     id: 0,
          //     matklName: '全部',
          //     isCheck: false
          //   }
          // ]// 物料组

          if (this.$route.query.id) {
            // 编辑 -》 选中
            // let matklList = this.infoForm.matklIds
            //   ? this.infoForm.matklIds.split(",")
            //   : [];
            // for (let item of res.data.data.baseMatklList) {
            //   for (const maItem of matklList) {
            //     if (item.id == maItem) {
            //       item.isCheck = true;
            //     }
            //   }
            // }
            this.baseMatklList.push(...res.data.data.baseMatklList);
            this.beforMatklist = res.data.data.baseMatklList
            this.beforMatklist.forEach(item=>{
              item.id = String(item.id)
            })

            // // 如果是编辑 数组长度和选中数组长度一样  全部按钮选中状态
            // if(res.data.data.baseMatklList.length == matklList.length){
            //   this.baseMatklList.forEach(item=>{
            //     if(item.id == 0) {
            //       item.isCheck = true;
            //     }
            //   })
            // }
          } else {
            this.baseMatklList.push(...res.data.data.baseMatklList);
            this.beforMatklist = res.data.data.baseMatklList
          }

          //获取下拉
          getSlt().then((sltres) => {
            this.$nextTick(() => {
              //基本回填
              // this.infoForm.jobsName = "业务人员";
              this.infoForm.custFullName = sltres.data.custName;
              this.infoForm.cisCode = sltres.data.cisCode;
              //下拉数据
              this.selectData = sltres.data;
              // 岗位  非代理运营商不展示自有渠道网格经理
              if(this.selectData.jobs && this.selectData.jobs.length>0){
                if(this.$store.state.user.userInfo.isAgentOperator == 0){
                  this.selectData.jobs = this.selectData.jobs.filter(function(obj) {
                    return obj.code != 20231110;
                  });
                }
              }
            });
          });
        })
        .catch(() => {
          this.spinning = false;
        });
    },

    // 按钮点击样式切换
    btnClickLevel(index, id, item) {
      // this.isActiveLevel = index
      // this.problemLevel = id
      item.isCheck = !item.isCheck;
      this.$forceUpdate()
      if (index == 0 && item.isCheck == true) {
        this.baseMatklList.forEach((item) => {
          item.isCheck = true;
        });
      }
      if (index == 0 && item.isCheck == false) {
        this.baseMatklList.forEach((item) => {
          item.isCheck = false;
        });
      }
      // 如果全部点击 按钮为
      let cherkIds = []
      if(index !== 0 ) {
        this.baseMatklList.forEach((items) => {
          if(items.id !== 0 && items.isCheck) {
            cherkIds.push(item)
          }
        });
        if(cherkIds.length == this.beforMatklist.length) {
          this.baseMatklList.forEach((items) => {
            if(items.id == 0) {
              items.isCheck = true
            }
          });
        } else {
          this.baseMatklList.forEach((items) => {
            if(items.id == 0) {
              items.isCheck = false
            }
          });
        }
      }

      //重新给隐藏input赋值
      let activeMatkIdArr = this.baseMatklList.filter((e) => {
        if (e.isCheck) {
          return true;
        }
      });

      this.infoForm.matklIds = this.$util
        .getArr(activeMatkIdArr, "id")
        .join(",");

      //验证 -》 不通过弹出提示
      this.$refs.ruleForm.validateField("matklIds", (valid) => {
        if (valid) {
          return false;
        }
      });
    },
    // 返回
    goback() {
      let this_ = this;
      if(this.editCount > 2){
        this.$confirm({
          title: '本次修改该尚未保存，是否确认离开',
          onOk() {
            this_.$router.go(-1);
          },
          onCancel() {},
        })
      }else{
        this_.$router.go(-1);
      }
    },
  
    //清空最高学历
    clearEduFun() {
      this.infoForm.educationId = "";
    },
    //出生日期不可选
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > this.$moment().endOf("day");
    },
    //生日选择
    birthdayChange(e, e2) {
      if (e) {
        this.infoForm.birthday = this.$util.dateFmt(new Date(e2), "yyyy-MM-dd");
        this.infoForm.age = this.getAge(e2);
      } else {
        this.infoForm.age = null;
      }

      //验证 -》 不通过弹出提示
      this.$refs.ruleForm.validateField("age", (valid) => {
        if (valid) {
          return false;
        }
      });
    },
    //毕业日期选择
    studyDateChange(e, e2) {
      if (e && e.length > 0) {
        this.infoForm.beginStudyDate = this.$util.dateFmt(
          new Date(e2[0]),
          "yyyy-MM-dd"
        );
        this.infoForm.endStudyDate = this.$util.dateFmt(
          new Date(e2[1]),
          "yyyy-MM-dd"
        );
      }
    },
    //根据选择日期获得年龄
    getAge2(str) {
      var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
      if (r == null) return false;
      var d = new Date(r[1], r[3] - 1, r[4]);
      if (
        d.getFullYear() == r[1] &&
        d.getMonth() + 1 == r[3] &&
        d.getDate() == r[4]
      ) {
        var Y = new Date().getFullYear();
        return Y - r[1];
      }
      return null;
      // return "输入的日期格式错误！";
    },
    //根据身份证号计算出年龄
    getAge(identify) {
			var UUserCard = identify;
			if (UUserCard != null && UUserCard != '') {
				//获取年龄
				var myDate = new Date();
				var month = myDate.getMonth() + 1;
				var day = myDate.getDate();
				var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
				if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) == month && UUserCard.substring(12, 14) <= day) {
					age++;
				}
				return age;
//				
			}
    },
    //通过身份证号码获取出生年月日
    getBirthdayFromIdCard(idCard) {
      var birthday = "";  
      if(idCard != null && idCard != ""){  
          if(idCard.length == 15){  
              birthday = "19"+idCard.substr(6,6);  
          } else if(idCard.length == 18){  
              birthday = idCard.substr(6,8);  
          }  
        
          birthday = birthday.replace(/(.{4})(.{2})/,"$1-$2-");  
      }
      return birthday;  
    }, 
    idChangeFun(e){
      // this.infoForm.idCard = e.target.value
      this.$forceUpdate()
      //验证 -》 不通过弹出提示
      this.$refs.ruleForm.validateField('idCard', (valid) => {
        if (!valid) {
          //验证通过
          this.infoForm.age = this.getAge(this.infoForm.idCard);
          this.infoForm.birthday = this.getBirthdayFromIdCard(this.infoForm.idCard);
          this.infoForm.sex = parseInt(this.infoForm.idCard.substr(16,1))%2==1 ?'1' :'0';
          return false
        }else{
          this.infoForm.age = '';
          this.infoForm.birthday = '';
        }
        this.$forceUpdate()
      });
    },
    //是否根据输入项进行筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    //暂存
    saveFun(){
      if (!this.infoForm.headPicId) {
        this.$message.info("请上传头像图片", 2);
        return; 
      }
      if(this.infoForm.workTimeId && this.infoForm.workTimeId != 90301 && this.infoForm.custAccountPersonWorkDtoList.length == 0) {
        this.$message.info("请添加工作经历", 2);
        return
      }
      if (this.isAddDialogShow) {
        this.$message.info("请将工作经历填写完整", 2);
        this.addDialogActive = true;
        //关闭工作经历闪烁提示动画
        let timer = setTimeout(() => {
          this.addDialogActive = false;
          clearTimeout(timer);
        }, 2200);
      } else {
        if(this.info && this.info.isIdCardEditable == 0) {
            this.infoForm.idCard = this.info.idCardOrg
        }
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.spinning = true;
            this.infoForm = { ...this.infoForm, 
              isEdit:this.$route.query.id ? true : false,
              marketCenterIds:Array.isArray(this.infoForm.marketCenterIds) ? this.infoForm.marketCenterIds.join(',') : this.infoForm.marketCenterIds,
              matklIds:Array.isArray(this.infoForm.matklIds) ? this.infoForm.matklIds.join(',') : this.infoForm.matklIds
            };
            
            let params = JSON.parse(JSON.stringify(this.infoForm));
            tempSave(params)
              .then((res) => {
                this.spinning = false;
                if (res.data.code == 0) {
                  this.$message.success("暂存成功");
                  this.$router.go(-1);
                } else {
                  this.$message.warning(res.data.msg);
                }
              })
              .catch(() => {this.spinning = false;});
          }else{
            this.$message.info("请将信息填写完整", 2);
          }
        });
      }
    },
    submitVerification() {
      if(this.$route.query.source == 'person' || this.$route.query.custAccountName ) {
        if(!this.same(this.oldMatklIds, this.infoForm.matklIds )){
          // 判断两个数组数据是否一致 如果不一致弹出提示 物料组变更后需要重新分配管辖区域和门店，是否确认更改。
          this.confirmToast = true
        } else {
          this.saveSubmit()
        }
      } else {
        this.saveSubmit()
      }
    },
    //提交
    saveSubmit() {

      if(!this.infoForm.jobsId) {
        this.$message.info("请选择岗位", 2);
        return;
      }
      if(!this.infoForm.ldapUserId && this.infoForm.jobsId == 20231110){
        this.$message.info("请选择办事处经理", 2);
        return;
      }
      if(this.$route.query.source == 'person' ) {
        if(!this.same(this.oldMatklIds, this.infoForm.matklIds )){
          // 判断两个数组数据是否一致 如果不一致弹出提示 物料组变更后需要重新分配管辖区域和门店，是否确认更改。
          this.confirmToast = true
        }
      }

      if (this.infoForm.recordType !=='生效' && this.infoForm.recordType !=='已绑定' && (!this.infoForm.idCardFrontId ||!this.infoForm.idCardBehindId) && this.isShowUpload ) {
        this.$message.info("请上传身份证图片", 2);
        return;
      }
      if (!this.infoForm.headPicId) {
        this.$message.info("请上传头像图片", 2);
        return; 
      }
      if(this.infoForm.workTimeId && this.infoForm.workTimeId != 90301 && (!this.infoForm.custAccountPersonWorkDtoList ||this.infoForm.custAccountPersonWorkDtoList.length == 0)) {
        this.$message.info("请添加工作经历", 2);
        return
      }
     
      if (this.isAddDialogShow) {
        this.$message.info("请将工作经历填写完整", 2);
        this.addDialogActive = true;
        //关闭工作经历闪烁提示动画
        let timer = setTimeout(() => {
          this.addDialogActive = false;
          clearTimeout(timer);
        }, 2200);
      } else {
        if(this.info && this.info.isIdCardEditable == 0) {
            this.infoForm.idCard = this.info.idCardOrg
        }
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.spinning = true;
            this.infoForm = { ...this.infoForm, 
              isEdit:this.$route.query.id && this.$route.query.isNoShenPi == 'true' ? true : false,
              marketCenterIds:Array.isArray(this.infoForm.marketCenterIds) ? this.infoForm.marketCenterIds.join(',') : this.infoForm.marketCenterIds,
              matklIds:Array.isArray(this.infoForm.matklIds) ? this.infoForm.matklIds.join(',') : this.infoForm.matklIds,
              };
            let params = JSON.parse(JSON.stringify(this.infoForm));
            if(this.$route.query.dimissionCheckStatus && this.$route.query.dimissionCheckStatus.indexOf('驳回') != '-1'){
              //离职流程状态是驳回 -》 走离职流程
              let params = {
                id: this.$route.query.id.toString(),
                marketCenterIds:Array.isArray(this.infoForm.marketCenterIds) ? this.infoForm.marketCenterIds.join(',') : this.infoForm.marketCenterIds
              };
              dimission(params)
                .then((res) => {
                  this.spinning = false;
                  this.$message.success("提交成功");
                  this.$router.go(-1);
                })
                .catch((res) => {
                  this.spinning = false;
                  this.$message.warning("提交失败，请联系管理员");
                });
            }else{
              //走提交流程
              save(params)
              .then((res) => {
                this.spinning = false;
                if (res.data.code == 0) {
                  this.$message.success("提交成功");
                  if( this.confirmStatus ){
                    // 清数据库管辖门店和区域接口
                    deleteShopByAccount({account:this.$route.query.custAccountName}).then(respones => {})
                  }
                  this.$router.go(-1);
                } else {
                  this.infoForm.marketCenterIds = this.infoForm.marketCenterIds.split(',')
                  if(this.$store.state.user.userInfo.isAgentOperator == 0) {
                    this.isShowUpload = false
                  } else {
                    this.isShowUpload = true
                  }
                  if(this.$route.query.id ){
                    this.infoForm.matklIds = this.infoForm.matklIds.split(',').map(String)
                  } else{
                    this.infoForm.matklIds = this.infoForm.matklIds.split(',').map(Number)
                  }
                  this.$message.warning(res.data.msg);
                  
                }
              })
              .catch(() => {this.spinning = false;});
            }
            
            
          }else{
            this.$message.info("请将信息填写完整", 2);
            this.infoForm.matklIds = this.infoForm.matklIds.split(',').map(String)
            
          }
        });
      }
    },
  },
};
