import http from "@/utils/request";
// 专卖店市场活动列表
export function activityList( data) {
  return http({
    url: 'cts/ctsApi.nd',
    method: 'post',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}
export function feedbackApi( data) {
  return http({
    url: 'cts/ctsApi.nd',
    method: 'post',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}

// 撤回

export function withdraw( data) {
  return http({
    url: '/exclusiveActivity/withdraw.nd',
    method: 'get',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  })
}
// 删除
export function deleteInfo( data) {
  return http({
    url: '/exclusiveActivity/delete.nd',
    method: 'get',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  })
}

// 下载模板
export function downPoster( data) {
  return http({
    url: '/exclusiveActivity/downPoster.nd',
    method: 'post',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    responseType: "arraybuffer",
    data
  })
}
// 下载模板
export function salesRebate( data) {
  return http({
    url: '/exclusiveActivity/salesRebate.nd',
    method: 'get',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    params:data
  })
}