/*
 * @Author: sqk
 * @Date: 2020-08-13 09:22:01
 * @LastEditTime: 2021-03-28 19:53:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\util.js
 */

export default {
  data: {
    "code": "0",
    "totalPages": 1,
    "pageSize": 999,
    "totalRows": 19,
    "list": [
      {
        "id": 10001124,
        "name": "安安",
        "sex": "0",
        "birthday": "2008-03-01",
        "nationId": null,
        "nationName": null,
        "politiceFaceId": 90202,
        "politiceFaceName": "中共党员",
        "marriage": "1",
        "idCard": "372219200803010001",
        "mobile": "18867890000",
        "hisenseStaff": "1",
        "email": "1789861127@qq.com",
        "headPicId": null,
        "headPicPath": null,
        "fullTimeEducation": "1",
        "educationId": 90406,
        "educationName": "本科",
        "school": "中国海洋大学",
        "major": "计算机技术",
        "beginStudyDate": "2016-09-01",
        "endStudyDate": "2020-07-01",
        "comeDate": null,
        "workTimeId": 90301,
        "workTimeString": "无经验",
        "custAccountId": 1704480,
        "custAccountName": "7097593",
        "cisCode": null,
        "custFullName": "青岛宇恒电器有限公司",
        "marketCenterName": null,
        "matklsName": null,
        "workExperience": null,
        "age": 21,
        "businessFileCode": "YWDA7388629001",
        "jobsId": 14181287852,
        "jobsName": "业务人员",
        "mdmCode": "2011383",
        "custAccountPersonWorkDtoList": null,
        "matklIds": "1200301,1310201",
        "marketCenterIds": null,
        "custId": 22565079
      },
      {
        "id": 10001125,
        "name": "安安",
        "sex": "0",
        "birthday": "2008-03-01",
        "nationId": null,
        "nationName": null,
        "politiceFaceId": 90202,
        "politiceFaceName": "中共党员",
        "marriage": "0",
        "idCard": "372219200803010001",
        "mobile": "18867890000",
        "hisenseStaff": "0",
        "email": "1789861127@qq.com",
        "headPicId": null,
        "headPicPath": null,
        "fullTimeEducation": "1",
        "educationId": 90406,
        "educationName": "本科",
        "school": "中国海洋大学",
        "major": "计算机技术",
        "beginStudyDate": "2016-09-01",
        "endStudyDate": "2020-07-01",
        "comeDate": null,
        "workTimeId": 90301,
        "workTimeString": "无经验",
        "custAccountId": 1704480,
        "custAccountName": "7097593",
        "cisCode": null,
        "custFullName": "青岛宇恒电器有限公司",
        "marketCenterName": null,
        "matklsName": null,
        "workExperience": null,
        "age": 21,
        "businessFileCode": "YWDA7388629001",
        "jobsId": 14181287852,
        "jobsName": "业务人员",
        "mdmCode": "2011383",
        "custAccountPersonWorkDtoList": null,
        "matklIds": "1200101,1100106",
        "marketCenterIds": null,
        "custId": 22565079
      },
      {
        "id": 10001126,
        "name": "安安",
        "sex": "1",
        "birthday": "2008-03-01",
        "nationId": null,
        "nationName": null,
        "politiceFaceId": 90202,
        "politiceFaceName": "中共党员",
        "marriage": "1",
        "idCard": "372219200803010001",
        "mobile": "18867890000",
        "hisenseStaff": "0",
        "email": "1789861127@qq.com",
        "headPicId": null,
        "headPicPath": null,
        "fullTimeEducation": "1",
        "educationId": 90406,
        "educationName": "本科",
        "school": "中国海洋大学",
        "major": "计算机技术",
        "beginStudyDate": "2016-09-01",
        "endStudyDate": "2020-07-01",
        "comeDate": null,
        "workTimeId": 90301,
        "workTimeString": "无经验",
        "custAccountId": 1704480,
        "custAccountName": "7097593",
        "cisCode": null,
        "custFullName": "青岛宇恒电器有限公司",
        "marketCenterName": null,
        "matklsName": null,
        "workExperience": null,
        "age": 21,
        "businessFileCode": null,
        "jobsId": 14181287852,
        "jobsName": "业务人员",
        "mdmCode": "2011383",
        "custAccountPersonWorkDtoList": null,
        "matklIds": "X0301,1100104,1100106",
        "marketCenterIds": null,
        "custId": 22565079
      },
      {
        "id": 10001127,
        "name": "安安",
        "sex": "0",
        "birthday": "2008-03-01",
        "nationId": null,
        "nationName": null,
        "politiceFaceId": 90202,
        "politiceFaceName": "中共党员",
        "marriage": "0",
        "idCard": "372219200803010001",
        "mobile": "18867890000",
        "hisenseStaff": "0",
        "email": "1789861127@qq.com",
        "headPicId": null,
        "headPicPath": null,
        "fullTimeEducation": "0",
        "educationId": 90406,
        "educationName": "本科",
        "school": "中国海洋大学",
        "major": "计算机技术",
        "beginStudyDate": "2016-09-01",
        "endStudyDate": "2020-07-01",
        "comeDate": null,
        "workTimeId": 90301,
        "workTimeString": "无经验",
        "custAccountId": 1704480,
        "custAccountName": "7097593",
        "cisCode": null,
        "custFullName": "青岛宇恒电器有限公司",
        "marketCenterName": null,
        "matklsName": null,
        "workExperience": null,
        "age": 21,
        "businessFileCode": null,
        "jobsId": 14181287852,
        "jobsName": "业务人员",
        "mdmCode": "2011383",
        "custAccountPersonWorkDtoList": null,
        "matklIds": null,
        "marketCenterIds": null,
        "custId": 22565079
      },
      {
        "id": 10001128,
        "name": "安安",
        "sex": "1",
        "birthday": "2008-03-01",
        "nationId": null,
        "nationName": null,
        "politiceFaceId": 90202,
        "politiceFaceName": "中共党员",
        "marriage": "1",
        "idCard": "372219200803010001",
        "mobile": "18867890000",
        "hisenseStaff": "0",
        "email": "1789861127@qq.com",
        "headPicId": null,
        "headPicPath": null,
        "fullTimeEducation": "0",
        "educationId": 90406,
        "educationName": "本科",
        "school": "中国海洋大学",
        "major": "计算机技术",
        "beginStudyDate": "2016-09-01",
        "endStudyDate": "2020-07-01",
        "comeDate": null,
        "workTimeId": 90301,
        "workTimeString": "无经验",
        "custAccountId": 1704480,
        "custAccountName": "7097593",
        "cisCode": null,
        "custFullName": "青岛宇恒电器有限公司",
        "marketCenterName": null,
        "matklsName": null,
        "workExperience": null,
        "age": 21,
        "businessFileCode": null,
        "jobsId": 14181287852,
        "jobsName": "业务人员",
        "mdmCode": "2011383",
        "custAccountPersonWorkDtoList": null,
        "matklIds": null,
        "marketCenterIds": null,
        "custId": 22565079
      },
      {
        "id": 10001129,
        "name": "安安",
        "sex": "1",
        "birthday": "2008-03-01",
        "nationId": null,
        "nationName": null,
        "politiceFaceId": 90202,
        "politiceFaceName": "中共党员",
        "marriage": "1",
        "idCard": "372219200803010001",
        "mobile": "18867890000",
        "hisenseStaff": "0",
        "email": "1789861127@qq.com",
        "headPicId": null,
        "headPicPath": null,
        "fullTimeEducation": "1",
        "educationId": 90406,
        "educationName": "本科",
        "school": "中国海洋大学",
        "major": "计算机技术",
        "beginStudyDate": "2016-09-01",
        "endStudyDate": "2020-07-01",
        "comeDate": null,
        "workTimeId": 90301,
        "workTimeString": "无经验",
        "custAccountId": 1704480,
        "custAccountName": "7097593",
        "cisCode": null,
        "custFullName": "青岛宇恒电器有限公司",
        "marketCenterName": null,
        "matklsName": null,
        "workExperience": null,
        "age": 21,
        "businessFileCode": null,
        "jobsId": 14181287852,
        "jobsName": "业务人员",
        "mdmCode": "2011383",
        "custAccountPersonWorkDtoList": null,
        "matklIds": null,
        "marketCenterIds": null,
        "custId": 22565079
      },
      {
        "id": 10001130,
        "name": "安安",
        "sex": "0",
        "birthday": "2008-03-01",
        "nationId": null,
        "nationName": null,
        "politiceFaceId": 90202,
        "politiceFaceName": "中共党员",
        "marriage": "0",
        "idCard": "372219200803010001",
        "mobile": "18867890000",
        "hisenseStaff": "0",
        "email": "1789861127@qq.com",
        "headPicId": null,
        "headPicPath": null,
        "fullTimeEducation": "1",
        "educationId": 90406,
        "educationName": "本科",
        "school": "中国海洋大学",
        "major": "计算机技术",
        "beginStudyDate": "2016-09-01",
        "endStudyDate": "2020-07-01",
        "comeDate": null,
        "workTimeId": 90301,
        "workTimeString": "无经验",
        "custAccountId": 1704480,
        "custAccountName": "7097593",
        "cisCode": null,
        "custFullName": "青岛宇恒电器有限公司",
        "marketCenterName": null,
        "matklsName": null,
        "workExperience": null,
        "age": 21,
        "businessFileCode": null,
        "jobsId": 14181287852,
        "jobsName": "业务人员",
        "mdmCode": "2011383",
        "custAccountPersonWorkDtoList": null,
        "matklIds": null,
        "marketCenterIds": null,
        "custId": 22565079
      }
    ],
    "currentPage": 1
  }
};
