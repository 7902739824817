
import http from "@/utils/request";
import {getStoreUser1} from "@/utils/store"


// ********************************************************

/**
 * 新物料组下拉框
 * @param {*} data 
 * @returns 
 */
 export function getMatklListNew() {
    return http({
        method: "get",
        url: `/distributorCreation/findMaterialGroupCombobox.nd`,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}
/**
 * 物料组下拉框
 * @param {*} data 
 * @returns 
 */
 export function getMatklList(data) {
    return http({
        method: "get",
        url: `/fxshop/getMatklList.nd?customerInfoId=${data}`,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

/**
 * 根据门店id获取 李现可 物料组下拉框 匹配所属办事处用 暂时不用
 * @param {*} data 
 * @returns 
 */
export function getMdMatklList(data) {
    return http({
        method: "get",
        url: `/grid/shop/getMatklList.nd?shopId=${data}`,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

/**
 * 提交保存
 * @param {*} data 
 * @returns 
 */
// 老版提交数据
// export function save(data) {
//     return http({
//         method: "post",
//         url: "/fxshop/shopStartFlow.nd",
//         contentType: "application/json;charset=UTF-8",
//         type: 'stringfy',
//         data: data
//     });
// }

// 新版提交数据
export function save(data) {
    var type=getStoreUser1()
    return http({
        method: "post",
        url: "/stepBuildStore/stepStartFlow/"+type+".nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data: data
    });
}

// 直接建店提交数据
export function saveStore(data) {
    return http({
        method: "post",
        url: "/fxshop/shopStartFlow.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data: data
    });
}

/**
 * 获取下拉列表
 * @param {*} data 
 * @returns 
 */
export function getParams() {
    return http({
        method: "post",
        url: "/fxshop/param.nd",
        contentType: "application/json;charset=UTF-8"
    });
}
// 获取门店物料组
export function queryMatklByFx() {
    return http({
        method: "get",
        url: "/fxshop/queryMatklByFx.nd?fxId=22756571&orgId=154",
        contentType: "application/json;charset=UTF-8"
    })
}

/**
 * 获取商家
 * @returns 
 */
export function getMerchant(data) {
    return http({
        // type: 'stringfy',
        params: data,
        method: "get",
        // url: "/fxshop/fxCustParam.nd?searchstr=" + data
        url: "/fxshop/fxCustParam.nd"
    });
}
// 管辖的门店获取商家

export function getMerchant2(data) {
    return http({
        // type: 'stringfy',
        params: data,
        method: "get",
        // url: "/fxshop/fxCustParam.nd?searchstr=" + data
        // url: "/fxshop/fxCustParam.nd"
        // url: "/grid/cust/fxCustSearch"
        url: "/grid/cust/fxCustSearch.nd"
        
    });
}
/**
 * 获取商家详情
 * @returns 
 */
export function getMerchantDetail(data) {
    return http({
        method: "get",
        url: "/fxshop/fxCustInfo.nd?id=" + data
    });
}

//获取办事处
export function getBscList(data) {
     return http({
        method: "get",
         url: "/fxshop/getBscList.nd?fgsId="+data,
    });
}
// 获取门店全称
export function getfullname(data) {
         return http({
           method: "post",
           url: "/stepBuildStore/isExistValue.nd",
           contentType: "application/json;charset=UTF-8",
           type: "stringfy",
           data:data
         });
       }

// 获取省
export function apply() {
    return http({
        method: "get",
        url: "/IntentionCust/apply.htm?source=b2b&&loginType=CS",

    });
}


// 获取城市接口
export function getCity(data) {
    return http({
        url: 'IntentionCust/getCity.nd',
        method: 'post',
        data
    })
}

// 获取区县
export function getArea(data) {
    return http({
        url: '/IntentionCust/getDistrict.nd',
        method: 'post',
        data
    })
}

// 获取乡镇街道
export function getStreet(data) {
    return http({
        url: 'IntentionCust/getTown.nd',
        method: 'post',
        data
    })
}

// 获取办事处
export function getBsc(data) {
    return http({
        method: "get",
        data,
        url: "/fxshop/queryOfficeOrg.nd?searchstr=&id=" + data
    });
}
//获取营销中心办事处
export function queryOffice(data) {
    return http({
        method: "get",
        url:"/fxshop/getTerminalByOrg.nd?officeId="+data,
    });
}

// 根据区县查询营销中心
export function getMarketCenter(data) {
    return http({
        url: '/fxshop/marketCenter.nd?countyId=' + data,
        method: 'get',
        contentType: "application/x-www-form-urlencoded",
        data
    })
}

// 查询区域负责人
export function getPrincipal(data) {
    return http({
        params: data,
        url: '/fxshop/getListByNameOrCode.nd',
        method: 'get',
    })
}

// 查询区域负责人详情
export function getPrincipalDetail(data) {
    return http({
        url: '/fxshop/getLdapUserInfoById.nd?id=' + data,
        method: 'get',
    })
}
// 门店需求同步提报-查询供应商-连锁统装派单
export function getSupplier(data) {
    return http({
        params: data,
        url: '/fxshop/allSupplierWithCode.nd',
        method: 'get',
    })
}
// 查询物料组
export function getWlz(fxId, orgId) {
    return http({
        url: '/fxshop/queryMatklByFx.nd?fxId=' + fxId + '&orgId=' + orgId,
        method: 'get',
    })
}
/**
 * 根据门店模糊查询
 * @param {*} searchstr  搜索字段
 * @param {*} type  1搜索全程，2搜索简称
 * @returns 
 */
export function getStore(data) {
    return http({
        params: data,
        url: '/fxshop/allShopNoCodeShopMatch.nd',
        method: 'get',
    })
}

/**
 * 查询营销员
 * @param {*} searchstr 
 * @param {*} officeId 
 * @returns 
 */
export function getSalesman(data) {
    return http({
        params: data,
        url: '/fxshop/getYxInfoList.nd',
        method: 'get',
    })
}
// 查询营销中心办事处
export function getMarketingCenter(officeId) {
    return http({
        url: '/fxshop/getTerminalByOrg.nd?officeId=' + officeId,
        method: 'get',
    })
}
// 管辖门店新增根据商家获取所属营销中心
export function getMarketingCenter2(custId) {
    return http({
        url: '/grid/cust/queryMarketOffice.nd?custId=' + custId,
        method: 'get',
    })
}

// 一步建店所属营销中心
export function getMarketingCenter3(materialGroupBranchCompany) {
    return http({
        url: '/grid/cust/queryMarketOffice.nd?materialGroupBranchCompany=' + materialGroupBranchCompany,
        method: 'get',
    })
}

// 下沉门店-所属运营商
// str,orgId,matklId
export function getOperator(data) {
    return http({
        params: data,
        url: '/fxshop/getRunCust.nd',
        method: 'get',
    })
}


// 门店详情
export function getDetail(data) {
    return http({
        url: '/fxshop/queryApplyById.nd?id=' + data,
        method: 'get',
    })
}

// 管辖的门店详情 暂时不用 与分销用一个接口->to李现可 12.14
export function getGxDetail(data) {
    return http({
        url: 'grid/shop/queryApplyById.nd?id=' + data,
        method: 'get',
    })
}
//验证营销员

export function stepBuildStoreParamCheck(data) {
    return http({
        url: '/stepBuildStore/stepBuildStoreParamCheck.nd?type=2' ,
        contentType: "application/json;charset=UTF-8",
        type: "stringfy",
        method: 'post',
        data
    })
}

export function uploadFile(data) {
    return http({
        method: "get",
        url: "/comm/method=uploadFile.nd",
        data
    });
}
// 查询BD
export function getBDList() {
    return http({
        method: "post",
        url: "/comm/getBd.nd",
        params: {}
    });
}

// 根据BD查询BU
export function getBUList(id) {
    return http({
        method: "post",
        url: `/comm/getBu.nd?bd=${id}`,
        params: {}
    });
}